import logo from './ct-logo.png';
import quest from './ct-innoquest-logo.png'
import './App.css';
import { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import SendIcon from '@material-ui/icons/Send';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  logo: {
    width: 200,
    padding: 10,
  },
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    margin: 2,
    padding: 20,
    width: '100%',
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute', top: '20%',
  },
  formField: {
    padding: 5
  }
}));

function App() {
  const [formFields, setFormFields] = useState([]);
  const [promptType, setPromptType] = useState('');
  const [userSubmission, setUserSubmission] = useState({});
  const [redirectState, setRedirectState] = useState(undefined);
  const [redirectUri, setRedirectUri] = useState(undefined);
  const [dataUriResult, setDataUriResult] = useState(undefined);
  const [whiteLabel, setWhiteLabel] = useState(undefined);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const enqueueError = err => {
    enqueueSnackbar(typeof err === 'string' ? err : err.toString(), {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query) {
      const redirect_uri = query.get('redirect_uri');
      const prompt = query.get('prompt');
      const type = query.get('type');
      const queryState = query.get('state');
      const wlStyle = query.get('wl-style');

      setWhiteLabel(wlStyle);

      if (prompt && redirect_uri && queryState) {
        setRedirectUri(redirect_uri);
        setRedirectState(queryState);
        if (type) {
          setPromptType(type);
        }
        const queryFormFieldObjects = JSON.parse(prompt);
        setFormFields(queryFormFieldObjects);
      } else {
        enqueueError('Error, prompt, redirect_uri and a valid state are required.');
      }
    }
  // eslint-disable-next-line
  }, []);

  const handleChange = (e, field) => {
    const { value } = e.target;
    setUserSubmission({
      ...userSubmission,
      [field]: value
    });
  };

  const handleContinue = async () => {
    // Confirm all form fields are included.
    const inComplete = formFields.filter(field => !userSubmission[field.property]);
    if (inComplete && inComplete.length) {
      enqueueError('All fields must be completed.');
    } else {
      // redirect back
      const requestBody = {
        userSubmission,
        state: redirectState,
      };

      try {
        const { status, data } = await axios.post(redirectUri, requestBody);
        if (status === 200 && data && data.redirect_uri) {
          // If an image data uri just show it as an img
          if (data.redirect_uri.startsWith('data:image/png;base64')) {
            setDataUriResult(data.redirect_uri);
          } else if (data.redirect_uri.startsWith('http')) {
            window.location = data.redirect_uri; 
          } else {
            enqueueError(`The redirect_uri to continue to is not supported: ${data.redirectUri}`);
          }
        } else {
          enqueueError('Could submit user details, please try again.');
        }
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.message) {
          enqueueError(err.response.data.message);
        } else {
          enqueueError(err);
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      {
        formFields && formFields.length > 0 && (
          <Paper elevation={20} className={classes.paper}>
            {(whiteLabel && whiteLabel === "innoquest") ? <img src={quest} className={classes.logo} style={{minWidth:'20rem'}} alt='logo' /> : <img src={logo} className={classes.logo} alt='logo' />}

            {/* If the redirect is just a data uri, show it */}
            {
              dataUriResult ? (
                <>
                <img alt='result' src={dataUriResult} />
                <Button  
                  fullWidth={true} 
                  variant='contained' 
                  color='primary'
                  href={dataUriResult}
                  download
                  startIcon={<GetAppIcon />}
                >
                  Download
                </Button>
                </>
              ) : (
                <>
                <Typography variant='body2'>
                  {promptType || 'Sign In'}
                </Typography>
                {/* <hr width="60%" style={{ marginBottom: 10 }} /> */}
                {
                  formFields.map((field, i) => (
                    <TextField
                      onChange={e => handleChange(e, field.property)}
                      required
                      className={classes.formField}
                      placeholder={field.description}
                      fullWidth={true}
                      key={i}
                      id={field.property}
                      label={field.name}
                      variant='outlined'
                    />
                  ))
                }
                <Button  
                  fullWidth={true} 
                  variant='contained' 
                  color='primary'
                  onClick={handleContinue} 
                  startIcon={<SendIcon />}
                >
                  Continue
                </Button>
                </>
              )
            }
          </Paper>
        )
      }
    </div>
  );
}

export default App;
